export const getFactDutyRequestParams = (
  startHourInterval: string,
  endHourInterval: string,
  department: string,
  staffQual: string[]
) => {
  return {
    objectCode: 'roster-duty',
    filter: [
      `(duty_type_ref.code_id=="А")`,
      `(duty_start=le=${startHourInterval})`,
      `(duty_end=ge=${endHourInterval})`,
      department ? `(staff_ref.comment=="${department}")` : undefined,
      staffQual.length > 0 ? `staff_ref.staff_qual=contain=(${staffQual.join(',')})` : undefined,
    ]
      .filter(Boolean)
      .join(';'),
    // groupBy: [],
    select: 'fact:count(*)',
  }
}
