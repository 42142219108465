import { FC, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormGenerator } from '@microservices/wiskey-react-components'
import { Box, Typography } from '@mui/material'
import { BarChart as MUIBarChart } from '@mui/x-charts/BarChart'

import { PageContentLayout } from '@layouts/PageContentLayout'
import { addLabels } from '@components/BarChart/helpers/addLabels'
import { useDataset } from '@components/BarChart/hooks/useDataset'
import { useDatasetParams } from '@components/BarChart/hooks/useDatasetParams'
import { useDefaultValues } from '@components/BarChart/hooks/useDefaultValues'
import { useFilterInputs } from '@components/BarChart/hooks/useFilterInputs'
import { PageTitle } from '@components/PageTitle'

import { AutocompleteOption } from '@types'

type BarChartProps = {
  id?: string
}

export type FormFiltersType = {
  planDay: string
  department: AutocompleteOption<string> | null
  qual: AutocompleteOption<string> | null
  // planQual: AutocompleteOption<string> | null
  // staffQual: AutocompleteOption<string>[]
}

export const BarChart: FC<BarChartProps> = () => {
  const { t } = useTranslation()

  const filterInputs = useFilterInputs()
  const defaultValues = useDefaultValues()
  const methods = useForm<FormFiltersType>({ defaultValues })
  const {
    formState: { isDirty },
  } = methods

  const datasetParams = useDatasetParams(methods, defaultValues)
  const { dataset, loading } = useDataset(datasetParams)

  // TODO Убрать логи
  useEffect(() => {
    console.log('datasetParams', datasetParams)
  }, [datasetParams])

  useEffect(() => {
    console.log('isDirty', isDirty)
  }, [isDirty])

  return (
    <Box>
      <PageTitle
        end={false}
        start={
          <>
            <Typography display={'inline'} sx={{ mr: 1, ml: 1 }} variant={'h6'}>
              {t('pageTitle.barChart')}
            </Typography>
            <Typography display={'inline'} variant={'subtitle2'}>
              {'Staff roster'}
            </Typography>
          </>
        }
      />
      <PageContentLayout>
        <Box p={2}>
          <FormProvider {...methods}>
            <FormGenerator inputs={filterInputs} mainGridSpacing={0} />
          </FormProvider>
        </Box>
        <MUIBarChart
          dataset={dataset}
          height={450}
          loading={loading}
          slotProps={{ legend: { hidden: true } }}
          width={1250}
          xAxis={[{ scaleType: 'band', dataKey: 'hour' }]}
          series={addLabels([
            { dataKey: 'plan', stack: 'A' },
            { dataKey: 'fact', stack: 'B' },
          ])}
        />
      </PageContentLayout>
    </Box>
  )
}
