import { useMemo } from 'react'

import { FormFiltersType } from '@components/BarChart/BarChart'

export const useDefaultValues = (): FormFiltersType => {
  return useMemo(
    () => ({
      planDay: '2025-03-26T21:00:00.000Z',
      department: { id: 'СПО_Агент', label: 'СПО_Агент' },
      qual: { id: 'ALL', label: 'ALL' },
      // planQual: { id: 'ALL', label: 'ALL' },
      // staffQual: [],
    }),
    []
  )
}
