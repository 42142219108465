import { useMemo } from 'react'

import { useRosterQualOptions } from '@components/BarChart/hooks/useRosterQualOptions'

import { GENERATOR_INPUT_TYPE } from '@constants'
import { AutocompleteOption } from '@types'

import { FormInputsType } from '../../../../../wiskey-react-components/src/types'

export const useFilterInputs = (): FormInputsType[] => {
  const departmentOptions: AutocompleteOption<string>[] = useMemo(
    () => [
      { id: 'СПО_Агент', label: 'СПО_Агент' },
      {
        id: 'ГПОО',
        label: 'ГПОО',
      },
    ],
    []
  )

  const planQualOptions: AutocompleteOption<string>[] = useMemo(
    () => [{ id: 'ALL', label: 'ALL' }],
    []
  )

  const qualOptions: AutocompleteOption<string>[] = useMemo(
    () => [
      { id: 'ALL', label: 'ALL' },
      { id: 'B-787', label: 'B-787' },
    ],
    []
  )

  const staffQualOptions = useRosterQualOptions()

  const filterInputs: FormInputsType[] = [
    {
      name: 'planDay',
      inputType: GENERATOR_INPUT_TYPE.DATE_TIME_PICKER,
      label: 'Plan Day',
      dateTimePickerViews: ['day'],
    },
    {
      name: 'qual',
      inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
      label: 'Qual',
      autocompleteOptions: qualOptions,
    },
    {
      name: 'department',
      inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
      label: 'Department',
      autocompleteOptions: departmentOptions,
    },
    // {
    //   name: 'planQual',
    //   inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
    //   label: 'Plan Qual',
    //   autocompleteOptions: planQualOptions,
    // },
    // {
    //   name: 'staffQual',
    //   inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
    //   label: 'Staff Qual',
    //   autocompleteOptions: staffQualOptions,
    //   multiple: true,
    // },
  ]

  return useMemo(() => filterInputs, [departmentOptions, staffQualOptions])
}
