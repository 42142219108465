import { useMemo } from 'react'
import { RouteObject, useRoutes } from 'react-router-dom'
import i18next from 'i18next'

import ConfiguredGanttLazy from '@pages/ConfiduredGantt/ConfiguredGanttLazy'
import { ConfigContextMenu } from '@pages/ConfigContextMenu'
import { ConfigControlPanels } from '@pages/ConfigControlPanels'
import { Dictionary } from '@pages/ConfigDictionary'
import { ConfigEntity } from '@pages/ConfigEntity'
import { ConfigForms } from '@pages/ConfigForms'
import { ConfigSearchAssistants } from '@pages/ConfigSearchAssistants'
import { ConfigStyleSettings } from '@pages/ConfigStyleSettings/ConfigStyleSettings'
import { ConfigTooltips } from '@pages/ConfigTooltips'
import { ConfiguredEntities } from '@pages/ConfiguredEntities'
import { ConfiguredEntityPage } from '@pages/ConfiguredEntityPage'
import { ContextCreateOrEdit } from '@pages/ContextCreateOrEdit'
import { ContextMenuCreateOrEdit } from '@pages/ContextMenuCreateOrEdit'
import { Contexts } from '@pages/Contexts'
import { ControlPanelsCreateOrEdit } from '@pages/ControlPanelsCreateOrEdit'
import { DisplayRules } from '@pages/DisplayRules'
import { DisplayRulesCreateOrEdit } from '@pages/DisplayRulesCreateOrEdit'
import { FormCreateOrEdit } from '@pages/FormCreateOrEdit'
import { FormPage } from '@pages/FormPage'
import { Parameters } from '@pages/Parameters'
import { SearchAssistantsCreateOrEdit } from '@pages/SearchAssistantsCreateOrEdit'
import { StyleSettingsCreateOrEdit } from '@pages/StyleSettingsCreateOrEdit'
import { TooltipCreateOrEdit } from '@pages/TooltipCreateOrEdit'
import { Variables } from '@pages/Variables'
import { VariableCreateOrEdit } from '@pages/Variables/components/VariableCreateOrEdit'

import { AppLayout } from '@layouts/AppLayout'
import { BarChart } from '@components/BarChart'
import { ChooseContext } from '@components/ChooseContext'
import { SwitchRouter } from '@components/SwitchRouter'

import {
  useFetchContextControlPanelByIdQuery,
  useFetchContextDefaultEntityBackgroundByIdQuery,
} from '@redux/api'
import { useFetchParameterPropertiesQuery } from '@redux/api/parameters.api'
import { AvailableGanttType } from '@redux/reducers/availableGantts.reducer'

import { useAppSelector, useAuth } from '@hooks'
import { getLocalStorageContext, getOptionRoute, transformCommonParametersToValues } from '@helpers'
import { COMMON_PARAMETERS, ELEMENT_TYPE, ENTITY, ROUTES, USER_ROLES } from '@constants'
import { GETControlPanelLineItem } from '@types'

import GanttChart, { GanttConfiguration, GanttCreateOrEdit } from '@gantt/GanttConfigLazy'

import { useDefaultTimezone } from '../../hooks/useDefaultTimezone'

import { generateEntityChildrenRoutes } from './helpers'

export const MainRoute = () => {
  const { hasRole } = useAuth()
  const context = getLocalStorageContext()

  const { gantts: availableGantts } = useAppSelector(state => state.availableGantts)

  const { data: menu, isLoading: isLoadingMenu } = useFetchContextControlPanelByIdQuery(
    context?.id,
    {
      skip: !context,
    }
  )

  const { data: defaultEntitiesBackground } = useFetchContextDefaultEntityBackgroundByIdQuery(
    context?.id,
    {
      skip: !context,
    }
  )

  const { data: commonParameters } = useFetchParameterPropertiesQuery({
    code: COMMON_PARAMETERS.STATE,
  })

  const initialCommonParameters = useMemo(
    () => transformCommonParametersToValues(commonParameters),
    [commonParameters]
  )

  useDefaultTimezone(initialCommonParameters)

  const addGanttRoutes = (gantts: AvailableGanttType[], routes: RouteObject[] = []) => {
    gantts.forEach(gantt => {
      routes.push({
        path: gantt.path,
        element: (
          <ConfiguredGanttLazy
            key={gantt.path}
            entityId={gantt.id}
            path={gantt.path}
            timezone={initialCommonParameters.timezone}
            title={gantt.title}
          />
        ),
        children: [
          {
            path: '/' + gantt.path + ROUTES.FORM,
            element: <FormPage />,
          },
        ],
      })
    })

    return routes
  }

  const addDefaultRoutes = (
    defaultEntitiesBackground: any | undefined,
    routes: RouteObject[] = []
  ): RouteObject[] => {
    if (!defaultEntitiesBackground?.length) {
      return []
    }

    if (defaultEntitiesBackground?.length === 1) {
      const { sourceId, title, type, code } = defaultEntitiesBackground[0]
      const path = getOptionRoute({ id: sourceId, title })

      const element =
        type === ELEMENT_TYPE.VIEW ? (
          <ConfiguredEntityPage
            entityCode={code}
            entityId={sourceId}
            title={title}
            type={ENTITY.VIEW}
            path={path}
            // For rerender
            key={path}
          />
        ) : (
          <ConfiguredGanttLazy
            key={path}
            entityId={sourceId}
            path={path}
            timezone={initialCommonParameters.timezone}
            title={title}
          />
        )

      routes.push({
        path,
        element,
        children: [
          {
            path: '/' + path + ROUTES.FORM,
            element: <FormPage />,
          },
        ],
      })
    }

    if (defaultEntitiesBackground?.length === 2) {
      const path = getOptionRoute(
        {
          id: defaultEntitiesBackground[0].sourceId,
          title: defaultEntitiesBackground[0].title,
        },
        {
          id: defaultEntitiesBackground[1].sourceId,
          title: defaultEntitiesBackground[1].title,
        }
      )

      routes.push({
        path,
        element: (
          <ConfiguredEntities
            // for rerender
            entities={defaultEntitiesBackground}
            path={path}
            timezone={initialCommonParameters.timezone}
          />
        ),
        children: [
          {
            path: '/' + path + ROUTES.FORM,
            element: <FormPage />,
          },
        ],
      })
    }

    return routes
  }

  // Создание роутов для динамически конфигурируемых страниц
  const createConfiguredRoutes = (menu: GETControlPanelLineItem[], routes: RouteObject[] = []) => {
    menu.forEach(menuItem => {
      const path = getOptionRoute(menuItem)

      if (menuItem.elementId && menuItem.elementType === ELEMENT_TYPE.VIEW) {
        routes.push({
          path,
          element: (
            <ConfiguredEntityPage
              entityCode={menuItem?.elementCode || undefined}
              entityId={menuItem?.elementId || undefined}
              title={menuItem.elementTitle || ''}
              type={ENTITY.VIEW}
              path={path}
              // For rerender
              key={path}
            />
          ),
          children: [
            // TODO открывается форма вместо View, на той же странице в Outlet.
            //  Неизвестно потребуется ли этот функционал после доработки с открытием Form и View через диалоговые окна.
            {
              path: '/' + path + ROUTES.FORM,
              element: <FormPage />,
            },
          ],
        })
      }

      if (menuItem.elementId && menuItem.elementType === ELEMENT_TYPE.GANTT_CHART) {
        routes.push({
          path,
          element: (
            <ConfiguredGanttLazy
              key={path}
              entityId={String(menuItem.elementId)}
              path={path}
              timezone={initialCommonParameters.timezone}
              title={menuItem.title}
            />
          ),
          children: [
            {
              path: '/' + path + ROUTES.FORM,
              element: <FormPage />,
            },
          ],
        })
      }

      if (menuItem.childrenMenu) {
        createConfiguredRoutes(menuItem.childrenMenu, routes)
      }
    })

    return routes
  }

  const renderByRoles = (roles: USER_ROLES[], routes: RouteObject[]): RouteObject[] => {
    return hasRole(roles) ? routes : []
  }

  const routes: RouteObject[] = useMemo(
    () => [
      ...renderByRoles(
        [USER_ROLES.ADMIN],
        [
          {
            element: <Contexts />,
            path: ROUTES.CONFIG_CONTEXTS,
            children: [
              {
                path: ROUTES.CONFIG_CONTEXTS_CREATE,
                element: <ContextCreateOrEdit />,
              },
              {
                path: ROUTES.CONFIG_CONTEXTS_EDIT_ID,
                element: <ContextCreateOrEdit />,
              },
            ],
          },
          {
            element: <ConfigEntity type={ENTITY.VIEW} />,
            path: ROUTES.CONFIG_VIEWS,
            children: generateEntityChildrenRoutes(ENTITY.VIEW),
          },
          {
            element: <ConfigEntity type={ENTITY.LIST_CONTROL} />,
            path: ROUTES.CONFIG_LIST_CONTROLS,
            children: generateEntityChildrenRoutes(ENTITY.LIST_CONTROL),
          },
          {
            element: <GanttChart />,
            path: ROUTES.CONFIG_GANTT,
            children: [
              {
                path: ROUTES.CONFIG_GANTT_CREATE,
                element: <GanttCreateOrEdit />,
                children: [
                  {
                    element: <GanttConfiguration />,
                    index: true,
                  },
                ],
              },
              {
                path: ROUTES.CONFIG_GANTT_EDIT,
                element: <GanttCreateOrEdit />,
                children: [
                  {
                    element: <GanttConfiguration />,
                    index: true,
                    path: ROUTES.CONFIG_GANTT_EDIT_CODE,
                  },
                ],
              },
            ],
          },
          {
            element: <ConfigEntity type={ENTITY.DROP_DOWN_ENTITY} />,
            path: ROUTES.CONFIG_DROP_DOWN_ENTITY,
            children: generateEntityChildrenRoutes(ENTITY.DROP_DOWN_ENTITY),
          },
          {
            element: <ConfigForms />,
            path: ROUTES.CONFIG_FORMS,
            children: [
              {
                path: ROUTES.CONFIG_FORMS_CREATE,
                element: <FormCreateOrEdit />,
              },
              {
                path: ROUTES.CONFIG_FORMS_EDIT_CODE,
                element: <FormCreateOrEdit />,
              },
            ],
          },
          {
            element: <ConfigControlPanels />,
            path: ROUTES.CONFIG_CONTROL_PANELS,
            children: [
              {
                path: ROUTES.CONFIG_CONTROL_PANELS_CREATE,
                element: <ControlPanelsCreateOrEdit />,
              },
              {
                path: ROUTES.CONFIG_CONTROL_PANELS_EDIT_ID,
                element: <ControlPanelsCreateOrEdit />,
              },
            ],
          },
          {
            element: <ConfigContextMenu />,
            path: ROUTES.CONFIG_CONTEXT_MENU,
            children: [
              {
                path: ROUTES.CONFIG_CONTEXT_MENU_CREATE,
                element: <ContextMenuCreateOrEdit />,
              },
              {
                path: ROUTES.CONFIG_CONTEXT_MENU_EDIT_ID,
                element: <ContextMenuCreateOrEdit />,
              },
            ],
          },
          {
            element: <ConfigStyleSettings />,
            path: ROUTES.CONFIG_STYLE_SETTINGS,
            children: [
              {
                path: ROUTES.CONFIG_STYLE_SETTINGS_CREATE,
                element: <StyleSettingsCreateOrEdit />,
              },
              {
                path: ROUTES.CONFIG_STYLE_SETTINGS_EDIT_ID,
                element: <StyleSettingsCreateOrEdit />,
              },
            ],
          },
          {
            element: <Dictionary />,
            path: ROUTES.DICTIONARY,
          },
          // {
          //   element: <SearchAssistant />,
          //   path: ROUTES.SEARCH_ASSISTANT,
          // },
          {
            element: <ConfigTooltips />,
            path: ROUTES.CONFIG_TOOLTIPS,
            children: [
              {
                path: ROUTES.CONFIG_TOOLTIPS_CREATE,
                element: <TooltipCreateOrEdit />,
              },
              {
                path: ROUTES.CONFIG_TOOLTIPS_EDIT_ID,
                element: <TooltipCreateOrEdit />,
              },
            ],
          },
          {
            element: <ConfigSearchAssistants />,
            path: ROUTES.CONFIG_SEARCH_ASSISTANTS,
            children: [
              {
                path: ROUTES.CONFIG_SEARCH_ASSISTANTS_CREATE,
                element: <SearchAssistantsCreateOrEdit />,
              },
              {
                path: ROUTES.CONFIG_SEARCH_ASSISTANTS_EDIT_ID,
                element: <SearchAssistantsCreateOrEdit />,
              },
            ],
          },
          {
            element: <Variables />,
            path: ROUTES.VARIABLES,
            children: [
              {
                path: ROUTES.VARIABLES_CREATE,
                element: <VariableCreateOrEdit />,
              },
              {
                path: ROUTES.VARIABLES_EDIT_ID,
                element: <VariableCreateOrEdit />,
              },
            ],
          },
          {
            element: <Parameters />,
            path: ROUTES.PARAMETERS,
          },
          {
            element: <DisplayRules />,
            path: ROUTES.CONFIG_DISPLAY_RULES,
            children: [
              {
                path: ROUTES.CONFIG_DISPLAY_RULES_CREATE,
                element: <DisplayRulesCreateOrEdit />,
              },
              {
                path: ROUTES.CONFIG_DISPLAY_RULES_EDIT_ID,
                element: <DisplayRulesCreateOrEdit />,
              },
            ],
          },
        ]
      ),
      {
        element: (
          <ConfiguredEntityPage
            key={ROUTES.SEARCH}
            path={ROUTES.SEARCH}
            title={i18next.t('searchAssistant.title')}
            type={ENTITY.VIEW}
          />
        ),
        path: ROUTES.SEARCH,
        children: [
          {
            path: '/' + ROUTES.SEARCH + ROUTES.FORM,
            element: <FormPage />,
          },
        ],
      },
      ...(menu?.panel?.lines
        ? menu.panel.lines.map(line => createConfiguredRoutes(line.items)).flat()
        : []),
      ...addGanttRoutes(availableGantts),
      ...addDefaultRoutes(defaultEntitiesBackground),
      ...(!isLoadingMenu
        ? [
            {
              element: <SwitchRouter defaultEntities={defaultEntitiesBackground} />,
              path: '*',
            },
          ]
        : []),
      {
        element: <ChooseContext />,
        path: ROUTES.CHOOSE_CONTEXT,
      },
      {
        element: <BarChart />,
        path: ROUTES.BAR_CHART,
      },
      {},
      ...(!defaultEntitiesBackground?.length ? [{ path: ROUTES.DEFAULT_PAGE }] : []),
    ],
    [initialCommonParameters, availableGantts, menu, defaultEntitiesBackground]
  )

  const appLayout = <AppLayout>{useRoutes(routes)}</AppLayout>

  return appLayout
}
